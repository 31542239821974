import { queryGoodsSubCate, queryBrandList, queryGoodsCate } from "@/api"

const state = {
  subCateList: [],
  cateList: [],
  brandList: [],
}

const mutations = {
  setSubCateList(state, list) {
    state.subCateList = list;
  },
  setCateList(state, list) {
    state.cateList = list;
  },
  setBrandList(state, list) {
    state.brandList = list;
  },
}

const actions = {
  async querySubCateList({commit,state},refresh = false) {
    if(!refresh && state.subCateList.length){
      return state.subCateList;
    }
    try{
      let { result } = await queryGoodsSubCate();
      commit('setSubCateList',result);
      return result;
    }catch(err){
      return null;
    }
  },
  async queryCateList({commit,state},refresh = false) {
    if(!refresh && state.cateList.length){
      return state.cateList;
    }
    try{
      let { result } = await queryGoodsCate({pageNo: 1, pageSize: 50});
      commit('setCateList',result.records || []);
      return result;
    }catch(err){
      return null;
    }
  },
  async queryBrandList({commit,state},refresh = false) {
    if(!refresh && state.brandList.length){
      return state.brandList;
    }
    try{
      let { result } = await queryBrandList({pageNo: 1, pageSize: 50});
      commit('setBrandList',result.records || []);
      return result;
    }catch(err){
      return null;
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
