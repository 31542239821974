import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'
import DefaultView from '../views/DefaultView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home/index'
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const asyncRoute = [
  {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    name: 'Home',
    meta: {
      title: '首页',
      icon: 'home',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/index'),
        name: 'HomeIndex',
        meta: {
          title: '首页',
          icon: 'home',
        }
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    redirect: '/order/index',
    name: 'order',
    meta: {
      title: '开单',
      icon: 'order',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/order/index'),
        name: 'OrderIndex',
        meta: {
          title: '开单',
          icon: 'order',
        }
      },{
        path: 'detail',
        component: () => import('@/views/order/detail'),
        name: 'OrderDetail',
        meta: {
          title: '开单-详情',
          icon: 'order',
        }
      }
    ]
  },
  {
    path: '/card',
    component: Layout,
    redirect: '/card/index',
    name: 'Card',
    meta: {
      title: '开卡',
      icon: 'card',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/card/index'),
        name: 'CardIndex',
        meta: {
          title: '开卡',
          icon: 'order',
        }
      },{
        path: 'detail',
        component: () => import('@/views/card/detail'),
        name: 'detail',
        meta: {
          title: '开卡-详情',
          icon: 'order',
        }
      }
    ]
  },
  {
    path: '/commission',
    component: Layout,
    redirect: '/commission/index',
    name: 'Commission',
    meta: {
      title: '提成',
      icon: 'commission',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/commission/index'),
        name: 'CommissionIndex',
        meta: {
          title: '提成',
          icon: 'commission',
        }
      }
    ]
  },
  {
    path: '/report',
    component: Layout,
    redirect: '/report/index',
    name: 'Report',
    meta: {
      title: '报表',
      icon: 'Report',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/report/index'),
        name: 'ReportIndex',
        meta: {
          title: '报表',
          icon: 'Report',
        }
      }
    ]
  },
  {
    path: '/member',
    component: Layout,
    redirect: '/member/list',
    name: 'Member',
    meta: {
      title: '会员',
      icon: 'Member',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/member-v1/index'),
        name: 'MemberIndex',
        meta: {
          title: '会员',
          icon: 'Member',
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [...routes,...asyncRoute]
})

export default router
