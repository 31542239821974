import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters: {
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
  },
  mutations: {
    setUser(state,data){
      state.userInfo=data
    },
    setToken(state,data){
      state.token=data
    },
  },
  actions: {
    loginOut(content){
      // const res = await getLoginOut()
      // if(res.code==200){
        content.commit('setUser',{})
        content.commit('setToken','')
        localStorage.clear()
        router.push('/login')
      // }
    }
  }
})

export default store
