const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

const TenantKey = 'Admin-Tenant'

export function getTenant(){
  return localStorage.getItem(TenantKey)
}

export function setTenant(val){
  return localStorage.setItem(TenantKey,val)
}

export function removeTenant() {
  return localStorage.removeItem(TenantKey)
}

const UserInfoKey = 'Admin-UserInfo'

export function getUserInfo(){
  let info = localStorage.getItem(UserInfoKey)
  return info ? JSON.parse(info) : {};
}

export function setUserInfo(val){
  return localStorage.setItem(UserInfoKey,JSON.stringify(val))
}

export function removeUserInfo() {
  return localStorage.removeItem(UserInfoKey)
}