<template>
	<el-dialog title="加服务" top="10vh" :visible.sync="dialogVisible" width="425px" custom-class="custom-dialog">
		<div class="add-main">
			<div class="add-table">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="type" label="服务类型" > </el-table-column>
					<el-table-column prop="price" label="价格" >
						<template slot-scope="scope">
							<div class=" text-danger" >
								{{scope.row.price}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="mode" label="计费模式"> </el-table-column>
					<el-table-column  label="操作">
						<template slot-scope="scope">
							<div class="action-item text-primary pointer" @click="selectServe(scope.row.type)">
								<i class="iconfont icon-file-lujing"></i>
								<span>使用</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="form-foot">
				<el-button class="btn" type="danger" @click="close">取消</el-button>
				<el-button class="btn" type="primary" @click="close">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				tableData:[
					{
						type:'手工服务',
						price:'10 %',
						mode:'按比例',
					},{
						type:'手工服务',
						price:'￥5',
						mode:'按价格',
					},{
						type:'手工服务',
						price:'10 %',
						mode:'按比例',
					},{
						type:'手工服务',
						price:'￥5',
						mode:'按价格',
					},{
						type:'手工服务',
						price:'10 %',
						mode:'按比例',
					},{
						type:'手工服务',
						price:'￥5',
						mode:'按价格',
					}
				]
			};
		},
		methods: {
			close(){
        this.dialogVisible = false
      },
      selectServe(val){
        this.$emit('selectServe', val);
      }
		},
	};
</script>
<style lang="scss" scoped>
	.action-item{
		.iconfont{
			margin-right: 5px;
		}
	}
	.form-foot {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		.btn {
			width: 100px;
			height: 42px;
			margin: 0 15px;
			border-radius: 4px;
			font-size: 16px;
		}

		.el-button--primary {
			box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
		}

		.el-button--default {
			border-radius: 4px;
			background: #FFFFFF;
			border: 1px solid #E7E7E7;
			box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
		}
	}
	.add-table{
		border: 1px solid #F2F3F5;
		border-radius: 10px;
		overflow: hidden;
	}
	.add-table ::v-deep .el-table th.el-table__cell{
		background: #F8F9FA;
		color: #333333;
	}
	::v-deep .custom-dialog{
		border-radius: 5px;
		overflow: hidden;
	}
	::v-deep .custom-dialog .el-dialog__header {
		background-color: #F8F9FA;
		font-size: 16px;
		margin-bottom: 0;
		height: 54px;
		display: flex;
		align-items: center;
		color: #000000;
		padding: 0 20px 0 30px;
		font-weight: 500;
		border-radius: 5px;
	}
	
	::v-deep .custom-dialog .el-dialog__body {
		padding: 20px 29px;
		background-color: #f2f3f5;
	}


</style>