<template>
  <div class="side-bar">
    <logo />
    <div class="nav-box">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <ul>
          <li 
            v-for="(item,index) in list" :key="index" 
            :class="{on: activeMenu==item.base}" 
          >
            <router-link key="expand" class="item" :to="item.link">
              <img class="icon" :src="getImageUrl(item.icon)" alt="">
              <img class="icon active" :src="getImageUrl(item.icon_active)" alt="">
              <div class="name">{{ item.name }}</div>
            </router-link>
            <i></i>
          </li>
        </ul>
      </el-scrollbar>
    </div>
    <div class="bottom" :data-id="activeMenu">
      <screenfull id="screenfull" class="icon" />
      <!-- <img src="@/assets/icon-full.png" alt=""> -->
      <span>全屏</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import Screenfull from '@/components/Screenfull'

export default {
  components: { Logo,Screenfull },
  data() {
    return {
      list: [
        {
          name: '首页',
          icon: 'home',
          icon_active: 'home-on',
          link: '/home/index',
          base: '/home'
        }, {
          name: '开单',
          icon: 'order',
          icon_active: 'order-on',
          link: '/order/index',
          base: '/order',
        }, {
          name: '开卡',
          icon: 'card',
          icon_active: 'card-on',
          link: '/card/index',
          base: '/card',
        },{
          name: '提成',
          icon: 'commission',
          icon_active: 'commission-on',
          link: '/commission/index',
          base: '/commission',
        }, {
          name: '报表',
          icon: 'report',
          icon_active: 'report-on',
          link: '/report/index',
          base: '/report',
        }, {
          name: '会员',
          icon: 'member',
          icon_active: 'member-on',
          link: '/member/index',
          base: '/member',
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      // 'permission_routes',
      // 'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { path } = route

      const match = path.match(/(^\/[^\/]*).*/,"$1")
      return match && match[1] ? match[1] : path;
    },
  },
  methods: {
    getImageUrl(icon) {
      return require(`@/assets/sidebar/${icon}.png`);
    }
  }
}
</script>
<style lang="scss" scoped>
  .side-bar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .nav-box {
      flex: 1;
      overflow: hidden;
      margin-top: -10px;
      :deep(.el-scrollbar) {
        height: 100%;
        overflow-x: hidden;
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
      
    }
    ul {
      text-align: center;
      overflow: hidden;
      padding: 20px 0;
      li {
        position: relative;
        padding: 12px 0;
        margin: 20px 0;
        i {
          opacity: 0;
          &::before,&::after {
            content: "";
            position: absolute;
            top: -39px;
            right: 0;
            width: 40px;
            height: 40px;
            background: url('@/assets/sidebar/icon-corner.png') no-repeat;
            background-size: 100% 100%;
          }
          &::after {
            top: unset;
            bottom: -39px;
            transform: rotate(270deg);
          }
        }
        .item {
          position: relative;
          display: block;
          .icon {
            display: block;
            width: 34px;
            height: 34px;
            margin: 0 auto;
            object-fit: contain;
            &.active {
              display: none;
            }
          }
          .name {
            font-size: 20px;
            font-weight: normal;
            line-height: 22px;
            color: #ffffff;
            margin-top: 10px;
          }
          
        }
        &.on {
          i {
            opacity: 1;
          }
          &::before {
            position: absolute;
            left: 20px;
            top: 0;
            width: 120%;
            height: 100%;
            content:  "";
            border-radius: 50px;
            background: #F2F3F5;
          }
          .item {
            padding-left: 10px;
            .name {
              color: #1661F7;
            }
            .icon {
              display: none;
              &.active {
                display: block;
              }
            }
          }
          
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      opacity: .5;
      padding: 30px 0;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
</style>