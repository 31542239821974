import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.css";
import '@/styles/index.scss' // global css
import '@/assets/fonts/iconfont.css' // global css
import cal from '@/utils/calculation';
import wsConnection from '@/utils/socket.js'

import './icons' // icon
import './permission'

Vue.config.productionTip = false
Vue.prototype.$setWs = wsConnection;
Vue.prototype.$cal = cal
Vue.prototype.$wsUrl = process.env.VUE_APP_BASE_WS

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
