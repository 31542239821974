import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, getTenant } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const tenantId = getTenant();
    if (tenantId) {
      config.headers['tenant-id'] = tenantId; 
    }
    if (store.getters.token) {
      config.headers['X-Access-Token'] = store.getters.token; 
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (res.code === 401) {
      // to re-login
      // MessageBox.confirm('请先登录', '提示', {
      //   confirmButtonText: '去登录',
      //   showCancelButton: false,
      //   showClose: false,
      //   closeOnClickModal: false,
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('user/clearInfo').then(() => {
      //     location.reload();
      //   })
      // })
      store.dispatch('user/clearInfo').then(() => {
        // location.reload()
        router.push('login');
        return Promise.reject(res)
      })
    }else if (res.success == false) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      Promise.reject(res).catch(()=>{
      })
      return res
    } else {
      return res
    }
  },
  error => {
    console.log('err' , error) // for debug

    if(error.message == 'Network Error'){
      // MessageBox.confirm('', '提示', {
      //   confirmButtonText: '去登录.',
      //   showCancelButton: false,
      //   showClose: false,
      //   closeOnClickModal: false,
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('user/clearInfo').then(() => {
      //     // location.reload()
      //     router.push('login');
      //   })
      // })
    }else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    
    return Promise.reject(error)
  }
)

export default service