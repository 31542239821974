import { login, logout, getInfo } from '@/api'
import { getToken, setToken, removeToken, getUserInfo, setUserInfo } from '@/utils/auth'

const state = {
  token: getToken(),
  userInfo: getUserInfo(),
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token);
  },
  SET_USERINFO: (state, info) => {
    state.userInfo = info;
    setUserInfo(info);
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, webClient } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), 
        password: password,
        webClient: webClient }).then(response => {
        const { result } = response
        commit('SET_TOKEN', result.token)
        commit('SET_USERINFO',result.userInfo)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        dispatch('clearInfo')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  clearInfo({commit}) {
    commit('SET_TOKEN', '')
    commit('SET_USERINFO', {})
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
